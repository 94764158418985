/** 
 * Common theme: all CSS variables available for the theme are listed in this class
 * Warning! CSS variables that depend on a calculation based on another CSS variable need 
 * to be defined in the client theme if the used CSS variable value is redefined in the 
 * client theme. Example: --fof-bot-msg-left-space depends on --fof-bot-msg-avatar-width
 */
.pby-bot-app {
  --fof-font-family: "Roboto", "Dinpro", "Helvetica", sans-serif;
  --fof-primary-color: #0000ff;
  --fof-primary-color-rgb: 0, 0, 255;
  --fof-secondary-color: #313135;
  --fof-secondary-color-rgb: 49, 49, 53;
  --fof-primary-bg-color: var(--fof-primary-color);
  --fof-primary-bg-text-color: white;
  --fof-secondary-bg-color: #f3f3f7;
  --fof-disabled-color: #aaaaaa;
  --fof-disabled-bg-color: #bbbbbb;
  --fof-border-color: #e6e6ee;
  --fof-main-border-width: 1px;
  --fof-tertiary-color: #8c8c8c;

  --fof-bot-msg-avatar-width: 1.6rem;
  /** Margin between the avatar and the bot message */
  --fof-bot-msg-avatar-right-margin: #{$spacer * 0.25};
  /** The left space to be left empty for other non text bot messages to align on the left with bot text messages */
  --fof-bot-msg-left-space: calc(
    var(--fof-bot-msg-avatar-width) + var(--fof-bot-msg-avatar-right-margin)
  );
  --fof-user-input-bg-color: var(--fof-secondary-bg-color);
  --fof-user-input-surround-bg-color: white;

  /** Header styling */
  --fof-header-border-color: var(--fof-border-color);
  --fof-header-bg-color: white;
  --fof-header-title-color: var(--fof-primary-color);
  --fof-header-message-color: var(--fof-secondary-color);
  --fof-header-bottom-corners-display: none;
  --fof-header-button-color: var(--fof-secondary-color);
  --fof-header-button-bg-color: transparent;
  --fof-header-button-border-color: var(--fof-border-color);
  --fof-header-button-hover-color: white;
  --fof-header-button-hover-bg-color: var(--fof-primary-color);
  --fof-header-button-hover-border-color: var(--fof-primary-color);

  --fof-offset-top: 0;
  --fof-header-logo-width: 3rem;
  --fof-header-logo-height: 3rem;
  --fof-header-logo-right-margin: 0;
  --fof-header-logo-translate-x: 0;
  --fof-header-logo-translate-y: 0;
  --fof-header-logo-scale: 1;

  --fof-lottie-left-margin: 0;
  --fof-lottie-right-margin: 0;
  --fof-lottie-translate-x: 0;
  --fof-lottie-translate-y: 0;
  --fof-lottie-scale: 1;
  --fof-lottie-background: none;

  // header banner
  --fof-header-banner-bg-color: #e5e5e5;
  --fof-header-banner-text-color: var(--fof-primary-color);
  --fof-header-banner-border-x-color: var(--fof-header-banner-bg-color);
  --fof-header-banner-border-bottom-color: var(--fof-header-banner-bg-color);

  --fof-header-banner-button-color: var(--fof-secondary-color);
  --fof-header-banner-button-bg-color: transparent;
  --fof-header-banner-button-border-color: var(--fof-secondary-color);
  --fof-header-banner-button-hover-color: white;
  --fof-header-banner-button-hover-bg-color: var(--fof-primary-color);
  --fof-header-banner-button-hover-border-color: var(--fof-primary-color);
  --fof-header-banner-bottom-corners-display: block;

  --fof-reduced-bot-avatar-width: 80px;
  --fof-reduced-bot-font-size: #{$font-size-base};
  --fof-reduced-bot-close-bubble-button-size: 1.2rem;

  --fof-icon-button-size: 2.5em;

  // large buttons (used in satisfaction evaluation for instance)
  --fof-large-button-label-font-size: #{$font-size-sm};

  // Override Bootstrap
  --bs-body-bg: white;
  --bs-primary: var(--fof-primary-color);
  --bs-primary-rgb: var(--fof-primary-color-rgb);
  --bs-secondary: var(--fof-secondary-color);
  --bs-secondary-rgb: var(--fof-secondary-color-rgb);

  --bs-link-color-rgb: var(--fof-primary-color-rgb);
  --bs-link-hover-color-rgb: var(--fof-primary-color-rgb);

  --bs-border-radius-xxl: 1.3rem;

  // Override Angular Material
  --mdc-filled-text-field-container-color: var(--fof-border-color);
  --mdc-filled-text-field-focus-label-text-color: var(--fof-primary-color);
  --mdc-filled-text-field-label-text-size: #{$font-size-base};
  --mat-form-field-container-text-size: calc(0.85 * #{$font-size-base});
  --mat-option-label-text-size: calc(0.85 * #{$font-size-base}) !important;
  --mat-select-trigger-text-size: calc(0.85 * #{$font-size-base});
  --mdc-filled-text-field-container-shape: calc(0.57 * #{$font-size-base});

  .mdc-text-field--filled {
    border-bottom-right-radius: var(--mdc-filled-text-field-container-shape);
    border-bottom-left-radius: var(--mdc-filled-text-field-container-shape);
  }

  .mat-mdc-form-field-infix {
    min-height: 2rem;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
    .mat-mdc-form-field-infix {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }

  // Do not display line under angular material forms-field
  .mdc-line-ripple {
    display: none;
  }
}

.pby-bot-arole,
.pby-bot-rela {
  // --fof-primary-color: #2B4C9B;
  // --fof-primary-color-rgb: 43, 73, 155;
  --fof-header-border-color: var(--fof-primary-color);
  --fof-header-bg-color: var(--fof-primary-color);
  --fof-header-title-color: white;
  --fof-header-message-color: white;
  --fof-header-button-color: white;
  --fof-header-button-bg-color: transparent;
  --fof-header-button-border-color: white;
  --fof-header-button-hover-color: var(--fof-primary-color);
  --fof-header-button-hover-bg-color: white;
  --fof-header-button-hover-border-color: white;
  --fof-header-bottom-corners-display: block;
}

.pby-bot-clickrh {
  --fof-font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  --fof-primary-color: #11448e;
  --fof-primary-color-rgb: 17, 68, 142;
  --fof-primary-bg-color: var(--fof-primary-color);
  --fof-main-border-width: 2px;

  --fof-header-border-color: var(--fof-primary-color);
  --fof-header-bg-color: var(--fof-primary-color);
  --fof-header-title-color: white;
  --fof-header-message-color: white;
  --fof-header-button-color: white;
  --fof-header-button-bg-color: transparent;
  --fof-header-button-border-color: white;
  --fof-header-button-hover-color: var(--fof-primary-color);
  --fof-header-button-hover-bg-color: white;
  --fof-header-button-hover-border-color: white;
  --fof-header-bottom-corners-display: block;

  --fof-offset-top: 1.3rem;
  --fof-header-logo-width: 3rem;
  --fof-header-logo-height: 3rem;
  --fof-header-logo-right-margin: 1.2rem;
  --fof-header-logo-translate-x: 1.5rem;
  --fof-header-logo-translate-y: -1.2rem;
  --fof-header-logo-scale: 1.7;

  --fof-header-banner-bg-color: #eaeaea;
  --fof-header-banner-button-border-color: var(--fof-primary-color);
  --fof-header-banner-button-color: var(--fof-primary-color);

  --fof-bot-msg-avatar-width: 1.9rem;
  --fof-bot-msg-avatar-right-margin: 0;
  --fof-bot-msg-left-space: var(--fof-bot-msg-avatar-width);

  // used in SVG images
  .svg-white {
    filter: brightness(0) invert(1);
  }
}

.pby-bot-digi {
  --fof-header-logo-width: 3rem;
  --fof-header-logo-height: 3rem;
  --fof-header-logo-right-margin: 0;
  --fof-header-logo-translate-x: 0;
  --fof-header-logo-translate-y: 0;
  --fof-header-logo-scale: 1;

  --fof-lottie-right-margin: 0;
  --fof-lottie-translate-x: 0;
  --fof-lottie-translate-y: -0.6rem;
  --fof-lottie-scale: 1.8;

  --fof-reduced-bot-avatar-width: 64px;
}

.pby-bot-douane {
  --fof-font-family: "Roboto", sans-serif;
  --fof-primary-color: #000091;
  --fof-primary-color-rgb: 0, 0, 145;
  --fof-primary-bg-color: var(--fof-primary-color);

  --fof-header-border-color: var(--fof-primary-color);
  --fof-header-bg-color: var(--fof-primary-color);
  --fof-header-title-color: white;
  --fof-header-message-color: white;
  --fof-header-button-color: white;
  --fof-header-button-bg-color: transparent;
  --fof-header-button-border-color: white;
  --fof-header-button-hover-color: var(--fof-primary-color);
  --fof-header-button-hover-bg-color: white;
  --fof-header-button-hover-border-color: white;
  --fof-header-bottom-corners-display: block;

  --bs-link-color-rgb: 255, 0, 16;
  --bs-link-hover-color-rgb: var(--bs-link-color-rgb);
}

.pby-bot-plume {
  --fof-header-logo-width: 3rem;
  --fof-header-logo-height: auto;
}

.pby-bot-pop {
  --fof-font-family: "Helvetica", sans-serif;
  --fof-primary-color: #0066b1;
  --fof-primary-color-rgb: 0, 102, 177;
  --fof-primary-bg-color: var(--fof-primary-color);

  --fof-header-border-color: var(--fof-primary-color);
  --fof-header-bg-color: var(--fof-primary-color);
  --fof-header-title-color: white;
  --fof-header-message-color: white;
  --fof-header-button-color: white;
  --fof-header-button-bg-color: transparent;
  --fof-header-button-border-color: white;
  --fof-header-button-hover-color: var(--fof-primary-color);
  --fof-header-button-hover-bg-color: white;
  --fof-header-button-hover-border-color: white;
  --fof-header-bottom-corners-display: block;

  --fof-header-logo-width: 3rem;
  --fof-header-logo-height: 3rem;
  --fof-header-logo-right-margin: 0;
  --fof-header-logo-translate-x: 0;
  --fof-header-logo-translate-y: 0;
  --fof-header-logo-scale: 1;

  --fof-lottie-left-margin: #{$spacer * 0.5};
  --fof-lottie-right-margin: #{$spacer * 0.5};
  --fof-lottie-translate-x: 0;
  --fof-lottie-translate-y: 0;
  --fof-lottie-scale: 1.8;
  --fof-lottie-background: radial-gradient(2.3rem circle at 50% 50%, white 50%, transparent 52%);

  --bs-link-color-rgb: var(--fof-primary-color-rgb);
  --bs-link-hover-color-rgb: var(--fof-primary-color-rgb);

  --fof-bot-msg-avatar-width: 1.9rem;
  /** Margin between the avatar and the bot message */
  --fof-bot-msg-avatar-right-margin: #{$spacer * 0.25};
  /** The left space to be left empty for other non text bot messages to align on the left with bot text messages */
  --fof-bot-msg-left-space: calc(
    var(--fof-bot-msg-avatar-width) + var(--fof-bot-msg-avatar-right-margin)
  );

  --fof-reduced-bot-avatar-width: 120px;
  --fof-reduced-bot-font-size: calc(1.2 * #{$font-size-base});
  --fof-reduced-bot-close-bubble-button-size: 1.5rem;

  --fof-large-button-label-font-size: 0.65rem;
}
